import { IconButton, Stack } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { Logo } from '@chainlit/app/src/components/atoms/logo';

import ChatProfiles from './ChatProfiles';
import NewChatButton from './NewChatButton';

interface Props {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

const Header = ({ expanded, setExpanded }: Props): JSX.Element => (
  <Stack
    px={2}
    py={1.5}
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    bgcolor="background.paper"
  >
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Logo style={{ maxHeight: '25px' }} />
       
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1}>
      <ChatProfiles />
      
      <NewChatButton />
      {expanded ? (
      <IconButton onClick={() => setExpanded(!expanded)} size="large">
          <CloseIcon sx={{ width: 24, height: 24 }} />
      
      </IconButton>)
      : <span/>
              }
    </Stack>
  </Stack>
);

export default Header;
