import Chat from 'chat';
import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import { Box, useMediaQuery } from '@mui/material';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';

import Header from 'components/Header';

interface Props {
  anchorEl?: HTMLElement | null;
  buttonHeight: string;
  setAnchorEl: (anchorEl: boolean) => void;
}

export default function PopOver({ anchorEl, buttonHeight, setAnchorEl }: Props) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));


  // Set expanded by default on mobile devices
  useEffect(() => {    
    if (isMobile) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [isMobile]);
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Escape') {
        setAnchorEl(null)
      }
    }, [expanded, setExpanded]
);
  
  return (
    <Popper
      id="chainlit-copilot-popover"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="top-end"
      onKeyDown={handleKeyDown}
      sx={{
        display: 'flex',
        position: expanded ? 'fixed!important' : 'initial',
        flexDirection: 'column',
        inset: expanded 
         ? 'auto auto 0px 0px !important'
         : 'auto 0 14px -24px',
        height: expanded
          ? `100%`
          : `min(730px, calc(100vh - ${buttonHeight} - 96px))`,
        width: expanded ? '100%' : 'min(400px, 80vw)',
        overflow: 'hidden',
        borderRadius: expanded ? '0px' : '12px',
        top: "auto!important",
        background: (theme:any) => theme.palette.background.default,
        transform: expanded ? 'translateY(0)!important': '',
        boxShadow:
          '0 6px 6px 0 rgba(167, 104, 104, 0.02),0 8px 24px 0 rgba(0,0,0,.12)!important',
        // transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        zIndex: 1200
      }}
    >
      <Fade in={!!anchorEl}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%'
          }}
        >
          <Header expanded={expanded} setExpanded={setAnchorEl} />
          <Chat />
        </Box>
      </Fade>
    </Popper>
  );
}
